<template>
  <div>
    <v-card flat class="shadow mt-3">
      <!-- filters -->
      <v-card flat class>
        <v-card-actions class="p-0">
          <v-btn small text @click="show = !show">
            <v-icon small left>fi fi-rr-settings-sliders</v-icon>
            <h4>فلاتر</h4>
          </v-btn>
          <v-spacer />

          <v-btn small text @click="printPage()">
            <v-icon small left>fi fi-rr-print</v-icon>
            <h4>طباعة</h4>
          </v-btn>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="show">
            <v-row class="align-center px-3">
              <v-col cols="12" md="3">
                <v-autocomplete
                  @input="getUsers(1, filter.pageSize, filter.search, filter.userType)"
                  :items="userType"
                  v-model="filter.userType"
                  item-text="text"
                  item-value="value"
                  clearable
                  dense
                  placeholder="المحافظة"
                  no-data-text="لا توجد بيانات"
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
        <v-divider :class="show ? 'mt-3' : 'mt-1'" />
      </v-card>
      <!-- header -->
      <div>
        <v-row align="center" class="pa-3">
          <v-col cols="12" md="2" class="mb-sm-1">
            <v-btn @click="openAddDialog()" color="primary" class="btn">
              <v-icon small class="pl-1">mdi-plus</v-icon>
              {{ this.$route.meta.single }} جديد
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="12" md="4">
            <div class="d-flex justify-space-between">
              <v-text-field
                @input="getUsers(1, filter.pageSize, filter.search, filter.userType)"
                v-model="filter.search"
                placeholder="ابحث هنا"
                hide-details
                dense
                class="pl-3"
                outlined
              />
              <v-select
                @change="getUsers(1, filter.pageSize, filter.search, filter.userType)"
                v-debounce:500ms="getUsers"
                :height="42"
                v-model="filter.pageSize"
                :items="pageSizes"
                no-data-text="لا توجد بيانات"
                style="max-width: 85px"
                hide-details
                outlined
                dense
              ></v-select>
            </div>
          </v-col>
        </v-row>
      </div>

      <!-- table -->
      <v-card flat tile>
        <v-data-table
          class="cursor-pointer"
          :items="users"
          :loading="loading"
          :headers="headers"
          :search="filter.search"
          hide-default-footer
          loading-text="جاري تحميل البيانات"
          no-data-text="لا توجد بيانات"
          :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
          :items-per-page="filter.pageSize"
        >
          <template v-slot:[`item.phoneNumber`]="{ item }">
            <b style="direction: rtl"
              >{{ item.phoneNumber.split(" ").join("").replace("+", "") }}+</b
            >
          </template>
          <template v-slot:[`item.fullName`]="{ item }">
            <router-link
              style="text-decoration: none"
              text
              :to="'userInvestmentsFlow/' + item.id"
              class="ma-0"
              v-if="item.type === 2"
            >
              {{ item.fullName }}
            </router-link>
            <div v-else>
              {{ item.fullName }}
            </div>
          </template>
          <template v-slot:[`item.dueDate`]="{ item }">
            <h5>{{ FormattedDate(item.dueDate) }}</h5>
          </template>
          <template v-slot:[`item.userType`]="{ item }">
            <v-chip :color="item.type === 1 ? 'info' : 'primary'">
              {{ item.type === 1 ? "مستخدم" : "شريك" }}
            </v-chip>
          </template>
          <template v-slot:[`item.code`]="{ item }">
            <div class="black--text" style="color: black">
              <!-- <qr-code :size="10" :text="item.userName"></qr-code> -->
              <v-dialog width="500">
                <template v-slot:activator="{ on: dialogOn, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        text
                        dark
                        v-if="item.id && item.nextPasswordResetToken"
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...dialogOn }"
                      >
                        <qr-code
                          color="#4a55a2"
                          bg-color="#fff"
                          :text="
                            generateTokenAndGuid(item.nextPasswordResetToken, item.id)
                          "
                          :size="35"
                        ></qr-code>
                      </v-btn>
                    </template>
                    <span>عرض QR</span>
                  </v-tooltip>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="primary lighten-2">
                      <h4>QR Code</h4>
                    </v-card-title>

                    <v-card-text
                      class="d-flex flex-column justify-center align-center my-3"
                    >
                      <qr-code
                        color="#4a55a2"
                        bg-color="#fff"
                        :text="generateTokenAndGuid(item.nextPasswordResetToken, item.id)"
                        :size="180"
                      ></qr-code>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialog.value = false">
                        إغلاق
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>

              <!-- {{ item.userName }} -->
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item, index }">
            <!-- <div style="min-width: 75px"> -->
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-btn color="primary" plain dark v-bind="attrs" v-on="menu">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>
              <v-list flat elevation="0">
                <!-- <v-tooltip right v-if="item.type === 2">
                                    <template v-slot:activator="{ on: onn, attrs: att }">
                                        <v-list-item v-bind="att" v-on="onn" dense link
                                            :to="'userInvestmentsFlow/' + item.id">
                                            <v-btn v-on="onn" icon depressed class="ml-3" x-small>
                                                <v-icon>mdi-sitemap-outline</v-icon>
                                            </v-btn>
                                        </v-list-item>
                                    </template>
                                    <span>عرض حركة المستثمر</span>
                                </v-tooltip> -->
                <v-tooltip right>
                  <template v-slot:activator="{ on: onn, attrs: att }">
                    <v-list-item v-bind="att" v-on="onn" dense>
                      <v-btn v-on="onn" icon depressed class="ml-3" x-small>
                        <edit
                          :user="item"
                          :id="item.id"
                          @refresh="
                            getUsers(
                              filter.pageIndex,
                              filter.pageSize,
                              filter.search,
                              filter.userType
                            )
                          "
                        />
                      </v-btn>
                    </v-list-item>
                  </template>
                  <span>تعديل</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on: onn, attrs: att }">
                    <v-list-item v-bind="att" v-on="onn" dense>
                      <v-btn v-on="onn" icon depressed class="ml-3" x-small>
                        <change-type
                          :user="item"
                          :id="item.id"
                          @refresh="
                            getUsers(
                              filter.pageIndex,
                              filter.pageSize,
                              filter.search,
                              filter.userType
                            )
                          "
                        />
                      </v-btn>
                    </v-list-item>
                  </template>
                  <span>تغير النوع</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on: onn, attrs: att }">
                    <v-list-item v-bind="att" v-on="onn" dense>
                      <v-btn v-on="onn" icon depressed class="ml-3" x-small>
                        <changePassword
                          :user="item"
                          :id="item.id"
                          @refresh="
                            getUsers(
                              filter.pageIndex,
                              filter.pageSize,
                              filter.search,
                              filter.userType
                            )
                          "
                        />
                      </v-btn>
                    </v-list-item>
                  </template>
                  <span>تغير كلمة المرور</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on: onn, attrs: att }">
                    <v-list-item v-bind="att" v-on="onn" dense>
                      <v-btn
                        @click="deleteItem(item.id, index)"
                        v-on="on"
                        x-small
                        icon
                        depressed
                      >
                        <v-icon>fi fi-rr-trash</v-icon>
                      </v-btn>
                    </v-list-item>
                  </template>
                  <span>حذف</span>
                </v-tooltip>
                <!-- <v-tooltip right>
                                    <template v-slot:activator="{ on: onn, attrs: att }">
                                        <v-list-item v-bind="att" v-on="onn" dense>
                                            <v-btn v-on="onn" x-small icon depressed @click.prevent="downloadExcel(item)">
                                                <v-icon>fi fi-rr-file-excel</v-icon>
                                            </v-btn>
                                        </v-list-item>
                                    </template>
                                    <span>تنزيل الاكسل</span>
                                </v-tooltip> -->
              </v-list>
            </v-menu>
            <v-tooltip right>
              <template v-slot:activator="{ on: onn, attrs: att }">
                <!-- <v-list-item  dense> -->
                <v-btn
                  v-on="onn"
                  v-bind="att"
                  x-small
                  icon
                  depressed
                  @click.prevent="downloadExcel(item)"
                >
                  <v-icon>fi fi-rr-file-excel</v-icon>
                </v-btn>
                <!-- </v-list-item> -->
              </template>
              <span>تنزيل الاكسل</span>
            </v-tooltip>
            <!-- </div> -->
          </template>
        </v-data-table>
      </v-card>

      <v-divider />
      <v-pagination
        v-model="filter.pageIndex"
        :length="length"
        :total-visible="7"
        @input="
          getUsers(filter.pageIndex, filter.pageSize, filter.search, filter.userType)
        "
      ></v-pagination>
      <!-- <Pagination :items="users" /> -->
    </v-card>
    <Dialog @refresh="getUsers(1, filter.pageSize, filter.search, filter.userType)" />
    <div style="display: none">
      <!-- Your custom HTML, including a table -->
      <div
        id="print-content"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <h1 style="margin: 12px">المستخدمين</h1>
        <table id="customers" border="1" style="width: 100%; direction: rtl">
          <thead>
            <tr>
              <th>الأسم</th>
              <th>الرقم</th>
              <th>اليوزر</th>
              <th>الكود</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in users" :key="index" style="direction: rtl">
              <td style="text-align: center; direction: rtl">
                {{ user.fullName }}
              </td>
              <td dir="auto" style="text-align: center">
                {{ user.phoneNumber }}
              </td>
              <td dir="auto" style="text-align: center">
                {{ user.userName }}
              </td>
              <td
                style="
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  padding-block: 15px;
                "
                dir="auto"
              >
                <qr-code
                  color="#4a55a2"
                  bg-color="#fff"
                  :text="generateTokenAndGuid(user.nextPasswordResetToken, user.id)"
                  :size="125"
                ></qr-code>
              </td>
            </tr>
            <!-- Add more rows as needed -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/../public/static/config.json";

export default {
  components: {
    Dialog: () => import("./dialog"),
    edit: () => import("./edit"),
    changeType: () => import("./changeType"),
    changePassword: () => import("./changePassword"),
    // VueQrcode,
  },

  data() {
    return {
      apiUrl:
        process.env.NODE_ENV === "production" ? config.APP_URL_PROD : config.APP_URL_DEV,
      userType: [
        { text: "بلا فلتر", value: null },
        { text: "شركاء", value: 2 },
        { text: "مستخدمين", value: 1 },
      ],
      show: false,
      headers: [
        {
          text: "الاسم الكامل",
          value: "fullName",
        },
        {
          text: "اسم االمستخدم",
          value: "userName",
        },
        {
          text: "رقم الهاتف",
          value: "phoneNumber",
        },
        // {
        //     text: "الصورة الشخصية",
        //     value: "imageUrl",
        //
        // },
        {
          text: "نوع",
          value: "userType",
        },
        {
          text: "الكود",
          value: "code",
        },
        {
          text: "تاريخ الأستحقاق",
          value: "dueDate",
        },

        {
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      users: [],
      loading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      length: 0,
      filter: {
        pageIndex: 1,
        pageSize: 15,
        search: null,
        userType: null,
        count: 0,
      },
      pageSizes: [5, 10, 15, 30, 50, 100],
    };
  },

  created() {
    this.getUsers(
      this.filter.pageIndex,
      this.filter.pageSize,
      this.filter.search,
      this.filter.userType
    );

    this.$eventBus.$on(`refresh`, () => {
      this.getUsers(
        this.filter.pageIndex,
        this.filter.pageSize,
        this.filter.search,
        this.filter.userType
      );
    });
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
  },

  methods: {
    FormattedDate(dateStr) {
      if (!dateStr || dateStr === "0001-01-01T00:00:00") {
        return "لايوجد";
      }
      const date = new Date(dateStr); // Your date object
      const formattedDate = new Intl.DateTimeFormat("en-SE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        // hour: "2-digit",
        // minute: "2-digit",
        // second: "2-digit",
        // hour12: false,
        // Use 24-hour format
      }).format(date);
      return formattedDate;
    },
    async downloadExcel(item) {
      const downloadUrl = `${this.apiUrl}/api/Excel/${item.id}`;

      try {
        // Making the request with axios and adding the Bearer Token to the headers
        const response = await this.$http.get(downloadUrl, {
          responseType: "blob", // Set the responseType to 'blob' for file download
        });
        console.log(response);
        // Handling the file download
        // Get the content disposition header from the response
        const contentDisposition = response.headers["content-disposition"];

        // Extract the filename from the content disposition header
        const match =
          contentDisposition && contentDisposition.match(/filename=(.*?)(;|$)/);
        const filename = `${item.fullName}.xlsx`;

        // Handling the file download
        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename; // Set the desired file name
        link.click();
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    },
    async printPage() {
      // Use the browser's print functionality
      await this.$store.dispatch("drawer/toggleOff");
      setTimeout(() => {
        // window.print();
        const printContent = document.getElementById("print-content").outerHTML;

        // Open a new window and write the content
        const printWindow = window.open("", "_blank");
        printWindow.document.write(
          `<html>
            <head>
                <title>Sama Babil Printer</title>
                <link rel="icon"  href="/blue_sama.png" />
                <link href='https://fonts.googleapis.com/css?family=Cairo' rel='stylesheet'>
                <style>
                *{
    font-family: 'Cairo';font-size: 22px;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4a55a2;
  color: white;
}
</style>
            </head>
            <body>`
        );
        printWindow.document.write(printContent);
        printWindow.document.write("</body></html>");

        // Close the document to ensure proper printing
        printWindow.document.close();

        // Trigger the print dialog
        printWindow.print();
      }, 500);
    },
    async getUsers(pageIndex, pageSize, search, userType) {
      this.users = [];
      this.loading = true;
      try {
        let query = `Users?IsDelete=false`;
        if (pageIndex !== undefined && pageIndex !== null) {
          query = query + `&PageIndex=${pageIndex}`;
        }
        if (pageSize !== undefined && pageSize !== null) {
          query = query + `&PageSize=${pageSize}`;
        }
        if (search !== undefined && search !== null) {
          query += `&Search=${search}`;
        }
        if (userType !== undefined && userType !== null) {
          query = query + `&UserType=${userType}`;
        }
        this.users = [];
        const res = await this.$http.get(query);
        console.log(res);
        //   const users
        this.users = res.data.result.data;
        // if (Array.isArray(users)) {
        //   users.forEach((user) => {
        //     // console.log(uuid());
        //     // user.nextPasswordResetToken = uuid();
        //     this.users.push(user);
        //   });
        // }
        this.filter.count = res.data.result.count;
        this.length =
          res.data.result.count === undefined
            ? 0
            : Math.ceil(res.data.result.count / this.filter.pageSize);
      } catch (err) {
        console.log(err);
        // service.faild(err.response.data.message);
      } finally {
        this.loading = false;
      }
    },
    async deleteItem(id, i) {
      this.$genericService.swalAlertConfirm({}).then((result) => {
        if (result.isConfirmed) {
          this.$genericService
            .delete(`${this.$route.meta.endPoint}`, id)
            .then((res) => {
              this.$store.dispatch("toastification/setToast", {
                message: `${res.data.message}`,
                type: "success",
              });
              this.users.splice(i, 1);
            })
            .catch((err) => {
              this.$store.dispatch("toastification/setToast", {
                message: `${err.data.message}`,
                type: "error",
              });
            });
        }
      });
    },
    openAddDialog() {
      var item = { dialogType: "add" };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },
    generateTokenAndGuid(token, guid) {
      // Combine token and GUID
      const combinedString = token + "|" + guid;
      // Encode as base64
      const combinedBase64 = btoa(combinedString);

      //   console.log("Combined Base64:", combinedBase64);
      //console.log(`id:${guid} \n token:${token}`);
      return combinedBase64;
    },
  },
};
</script>
